import { PeraWalletConnect } from "@perawallet/connect";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import algosdk, { Algodv2 } from "algosdk";
import { Dispatch, SetStateAction } from "react";
import {
  algoAsset,
  ALGORAND_ASSET_IMAGE_URL,
  MAIN_ASSET_ID,
  milkomedaAsset,
} from "./constants";
import MyAlgo from "./Mainchains/Algorand/MyAlgo";
import PeraWallet from "./Mainchains/Algorand/PeraWallet";
import {
  AccountDetails,
  AlgorandWallet,
  AlgorandWalletsProviders,
  CustomAsset,
  DappSettings,
  IMainchainOptions,
  SourceDirections,
  TokenRegistryEntry,
} from "../types/wallet";
import AlgorandHandler from "./Mainchains/Algorand";
import { isCorrectAlgorandAddress } from "./utils";
import { ethers } from "ethers";

class Executor {
  public static async decideOnMainchainWalletConnect(
    algodUrl: string,
    algodApiKey: string,
    chosenWalletProvider: AlgorandWalletsProviders,
    isWrapping: boolean,
    setOriginAccount: Dispatch<SetStateAction<AccountDetails | undefined>>,
    setDestinationAccount: Dispatch<SetStateAction<AccountDetails | undefined>>,
    setAlgoWalletConnected: any
  ): Promise<boolean> {
    let wasSuccessfullyConnected: boolean = false;
    try {
      switch (chosenWalletProvider) {
        case AlgorandWalletsProviders.MyAlgo: {
          if (isWrapping) {
            wasSuccessfullyConnected = await MyAlgo.connect(
              algodUrl,
              algodApiKey,
              setOriginAccount,
              setAlgoWalletConnected
            );
          } else {
            wasSuccessfullyConnected = await MyAlgo.connect(
              algodUrl,
              algodApiKey,
              setDestinationAccount,
              setAlgoWalletConnected
            );
          }
          break;
        }
        case AlgorandWalletsProviders.PeraWallet: {
          if (isWrapping) {
            wasSuccessfullyConnected = await PeraWallet.reconnect(
              algodUrl,
              algodApiKey,
              setOriginAccount,
              setAlgoWalletConnected
            );
          } else {
            wasSuccessfullyConnected = await PeraWallet.reconnect(
              algodUrl,
              algodApiKey,
              setDestinationAccount,
              setAlgoWalletConnected
            );
          }
          break;
        }
        default:
          wasSuccessfullyConnected = false;
          break;
      }
    } catch (e) {
      // console.error(e);
      wasSuccessfullyConnected = false;
    }
    return wasSuccessfullyConnected;
  }

  public static async decideOnMainchainWalletDisconnect(
    isWrapping: boolean,
    setOriginAccount: Dispatch<SetStateAction<AccountDetails | undefined>>,
    setDestinationAccount: Dispatch<SetStateAction<AccountDetails | undefined>>,
    setSelectedMainchainWallet: any
  ): Promise<void> {
    // in case of other mainchain wallets: todo - add handler for multiple ones
    await PeraWallet.handleDisconnectWalletClick();
    setSelectedMainchainWallet(undefined);
    if (isWrapping) {
      setOriginAccount(undefined);
    } else {
      setDestinationAccount(undefined);
    }
  }

  public static signAndSendWrapping = async (
    selectedMainchainWallet: AlgorandWallet | null,
    algodClient: Algodv2 | null,
    algoWalletConnected: unknown,
    stargateAddress: string,
    originAccount: AccountDetails,
    destinationAccount: AccountDetails,
    sendAmountWithFees: string | undefined,
    sendAmount: string | null,
    setMainchainTx: Dispatch<SetStateAction<string>>
  ): Promise<string | undefined | null> => {
    let wallet = selectedMainchainWallet as AlgorandWallet;
    let tx: string | undefined | null;
    if (wallet.provider === AlgorandWalletsProviders.MyAlgo) {
      tx = await MyAlgo.signAndSendWrappingAlgorand(
        algodClient,
        algoWalletConnected as unknown as MyAlgoConnect,
        stargateAddress,
        originAccount,
        destinationAccount,
        sendAmountWithFees,
        sendAmount
      );
      setMainchainTx(tx as string);
      return tx;
    }

    if (wallet.provider === AlgorandWalletsProviders.PeraWallet) {
      tx = await PeraWallet.signAndSendWrappingAlgorand(
        algodClient,
        algoWalletConnected as unknown as PeraWalletConnect,
        stargateAddress,
        originAccount,
        destinationAccount,
        sendAmountWithFees,
        sendAmount,
        setMainchainTx
      );
      setMainchainTx(tx as string);
      return tx;
    }
    return tx;
  };

  // call if any mainchain wallet needs reconnection functionality
  // todo: extend types such as AlgorandWallet to be more generic once another blockchain is integrated
  public static async reconnectMainchainWallet(
    algodUrl: string,
    algodApiKey: string,
    selectedMainchainWallet: AlgorandWallet | null,
    isWrapping: boolean,
    setOriginAccount: Dispatch<SetStateAction<AccountDetails | undefined>>,
    setDestinationAccount: Dispatch<SetStateAction<AccountDetails | undefined>>,
    setAlgoWalletConnected: any,
    selectedAsset?: CustomAsset
  ): Promise<void> {
    if (selectedMainchainWallet == null) return;
    if (selectedMainchainWallet.provider === AlgorandWalletsProviders.PeraWallet) {
      if (isWrapping) {
        await PeraWallet.reconnect(
          algodUrl,
          algodApiKey,
          setOriginAccount,
          setAlgoWalletConnected,
          selectedAsset
        );
      } else {
        await PeraWallet.reconnect(
          algodUrl,
          algodApiKey,
          setDestinationAccount,
          setAlgoWalletConnected
        );
      }
    }
  }

  public static awaitMainchainConfirmationOnTx = async (
    txId: string | null,
    isWrapping: boolean,
    dappSettings: DappSettings | undefined,
    options: IMainchainOptions | undefined,
    setMainchainTxConfirmed: any,
    setFailedConfirmationModal: any
  ): Promise<void> => {
    if (!txId || !isWrapping || options === undefined) return;

    try {
      setMainchainTxConfirmed(false);
      if (
        dappSettings?.selectedToken.id === options.algorand?.algoAsset?.id &&
        options.algorand?.algodClient
      ) {
        await algosdk.waitForConfirmation(options.algorand.algodClient, txId, 4);
      }
      // todo: add option for cardano
      setMainchainTxConfirmed(true);
    } catch (e) {
      const err = e as Error;
      console.log(`There was something wrong with the confirmation.`);
      console.error(err.message);
      setTimeout(() => setFailedConfirmationModal(true), 1000);
    }
  };

  // We need to extract Algorand Asset ID (which is 8-10 last characters of our asset id tokens registry)
  // exemplary asset id to process: a553700000000000000000000000000000000000000000000000000012400859
  // a5537 - prefix needed inside the bridge
  // 0s - fill the asset id, their amount depends on how many characters has the Algorand asset id
  // 12400859 - actual Algorand Asset id value which is used by all Algorand sdks to fetch information about the asset from the Algorand node
  private static getAlgorandAssetId = (assetId: string): string => {
    const prefixLength = "a5537".length;
    const removedPrefix = assetId.slice(prefixLength);

    let zerosWithId = removedPrefix.split("");
    let zerosCounter = 0;
    for (let index in zerosWithId) {
      if (zerosWithId[index] === "0") {
        zerosCounter++;
      } else {
        break;
      }
    }
    const trimmedAssetId = zerosWithId.slice(zerosCounter).join("");
    return trimmedAssetId;
  };

  public static getAvailableAssets = async (
    milkomedaIndexerUrl: string,
    algodUrl: string,
    algodApiKey: string,
    sourceDirection?: SourceDirections,
    assetLogoBaseUrl?: string
  ): Promise<CustomAsset[]> => {
    let assets: CustomAsset[] = [];

    if (sourceDirection !== undefined) {
      if (sourceDirection === SourceDirections.Algorand) {
        assets.push({
          ...algoAsset,
          symbol: "ALGO",
          balance: "",
          assetId: MAIN_ASSET_ID,
        });
      } else if (sourceDirection === SourceDirections.MilkomedaA1) {
        assets.push({
          ...milkomedaAsset,
          symbol: "mALGO",
          balance: "",
          assetId: MAIN_ASSET_ID,
        });
      }
      try {
        // get tokens from token registry
        const response = await fetch(`${milkomedaIndexerUrl}/assets?active=true`);
        const data = (await response.json()) as TokenRegistryEntry[];

        // todo: detect selected network and get token details based on this
        // for now we only care about Algorand
        const algodClient = AlgorandHandler.getAlgodClient(algodUrl, algodApiKey);
        for (let res of data) {
          if (res.asset_id !== MAIN_ASSET_ID) {
            try {
              const algorandAssetId = Executor.getAlgorandAssetId(res.asset_id);
              const assetDetails = await algodClient
                .getAssetByID(Number(algorandAssetId))
                .do();
              const symbol = assetDetails.params["unit-name"] ?? algorandAssetId;
              const decimals = assetDetails.params["decimals"] ?? 0;
              if (res.asset_id !== MAIN_ASSET_ID) {
                assets.push({
                  id: algorandAssetId,
                  assetId: res.asset_id,
                  icon: `${
                    assetLogoBaseUrl ?? ALGORAND_ASSET_IMAGE_URL
                  }${algorandAssetId}.image`,
                  symbol: symbol,
                  tokenContract: res.token_contract,
                  balance: "0.00", // we don't care about this one here, balance will be updated based on assets stored on the origin account
                  decimals: decimals,
                });
              }
            } catch (e) {
              console.error(e);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    return assets;
  };

  // add more conditioning here - move from the UI component
  public static ensureAddressesAreCorrect = (
    isWrapping: boolean,
    originAddress: string,
    destinationAddress: string
  ): boolean => {
    if (isWrapping) {
      return (
        isCorrectAlgorandAddress(originAddress) &&
        ethers.utils.isAddress(destinationAddress)
      );
    }
    return (
      ethers.utils.isAddress(originAddress) &&
      isCorrectAlgorandAddress(destinationAddress)
    );
  };
}

export default Executor;
