import { Web3Provider } from "@ethersproject/providers";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import algosdk, { Algodv2 } from "algosdk";
import { ethers } from "ethers";
import React, { createContext, useContext, useEffect, useState } from "react";
import SideChainBridge from "../abi/SidechainBridge.json";
import Evm from "../services/EVM";
import Executor from "../services/executor";
import { getWrappingFee, isValidStr } from "../services/utils";
import { AccountDetails, SourceDirections } from "../types/wallet";
import { initAppVersion } from "../utils/appVersioning";
import { useAppProvider } from "./AppContext";
import { useNetwork } from "./NetworkContext";

type AppContextType = {
  ethProvider: Web3Provider | null;
  setEthProvider: React.Dispatch<React.SetStateAction<Web3Provider | null>>;
  algoConnect: MyAlgoConnect | null;
  algodClient: Algodv2 | null;
  setAlgodClient: React.Dispatch<React.SetStateAction<Algodv2 | null>>;
  wrappingFee: string;
  loadAccount: (provider: Web3Provider) => Promise<AccountDetails | undefined>;
  evmNetworkChosen: string;
};

const Web3Context = createContext({} as AppContextType);

export const Web3Providers = ({ children }: { children: React.ReactNode }) => {
  const [ethProvider, setEthProvider] = useState<Web3Provider | null>(null);
  const [algoConnect, setAlgoConnect] = useState<MyAlgoConnect | null>(null);
  const [algodClient, setAlgodClient] = useState<Algodv2 | null>(null);
  const {
    selectedNetwork,
    ALGOD_URL,
    ALGOD_API_KEY,
    ALGORAND_SERVICE_FEE,
    CONTRACT_ADDRESS_A1_EVM,
  } = useNetwork();
  // service fee should be taken by default from the contract, however if something goes wrong, we need to be sure we include the service fee, hence there's
  // default value provided in the config jic
  const [wrappingFee, setWrappingFee] = useState<string>(ALGORAND_SERVICE_FEE);
  const [evmNetworkChosen, setEvmNetworkChosen] = useState<string>("");

  const { isWrapping, setOriginAccount, setDestinationAccount } = useAppProvider();

  const connectMyAlgo = async () => {
    try {
      let myAlgoConnect = new MyAlgoConnect();
      setAlgoConnect(myAlgoConnect);

      // const { REACT_APP_ALGORAND_API_KEY } = process.env;
      const REACT_APP_ALGORAND_API_KEY = ALGOD_API_KEY;
      let algodClient: Algodv2;
      // if api key provided use purestake or any other service
      // if not then use default algorand testnet, but account info can be fetched
      if (isValidStr(REACT_APP_ALGORAND_API_KEY) && isValidStr(ALGOD_URL)) {
        const algoServer = ALGOD_URL as string;
        const API_KEY = { "X-Algo-API-Token": REACT_APP_ALGORAND_API_KEY as string };
        algodClient = new algosdk.Algodv2(API_KEY, algoServer, "");
      } else {
        // no or incorrect api key provided. No balance will be fetched
        algodClient = new algosdk.Algodv2(
          "",
          "https://node.testnet.algoexplorerapi.io",
          ""
        );
      }
      setAlgodClient(algodClient);
    } catch (e) {
      const err = e as Error;
      console.log(`MyAlgo not connected. Please try again. ${err.message}`);
      return;
    }
  };

  const loadEthProvider = () => {
    const ethProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
    setEthProvider(ethProvider);
    return ethProvider;
  };

  // since we're using values taken from other places, it's nice to be sure, the function rerendered with new values, hence useCallback is added
  const loadAccount = React.useCallback(
    async (provider: Web3Provider): Promise<AccountDetails | undefined> => {
      // if we don't check if isWrapping is undefined, then we're calling this twice causing both wallet fields to be field
      // since if isWrapping is undefined, the isWrapping === true won't be true in 2 cases, so in summary we have to take care of 3 cases
      // where we only care about 2 of them - whether it is true or not
      if (isWrapping !== undefined) {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const accountAddress = ethers.utils.getAddress(accounts[0]);
        const setAccount = isWrapping === true ? setDestinationAccount : setOriginAccount;

        const tokenRegistryList = await Executor.getAvailableAssets(
          selectedNetwork.common.indexerURL,
          selectedNetwork.algorand.algodURL,
          ALGOD_API_KEY,
          SourceDirections.MilkomedaA1,
          selectedNetwork.algorand.assetLogoBaseUrl
        );

        const assets = await Evm.getEvmTokensWithBalances(
          provider as ethers.providers.Web3Provider,
          accountAddress,
          tokenRegistryList
        );
        const account = {
          address: accountAddress,
          assets: [...assets],
          currentAsset: { ...assets[0] },
        };

        setAccount(account);
        return account;
      }
    },
    [
      ALGOD_API_KEY,
      isWrapping,
      selectedNetwork.algorand.algodURL,
      selectedNetwork.common.indexerURL,
      setDestinationAccount,
      setOriginAccount,
      selectedNetwork.algorand.assetLogoBaseUrl,
    ]
  );

  const connectMetamaskProvider = async () => {
    if (window.ethereum) {
      const provider = loadEthProvider();

      const wrappingFee = await getWrappingFee(
        provider,
        SideChainBridge["abi"],
        CONTRACT_ADDRESS_A1_EVM,
        ALGORAND_SERVICE_FEE
      );
      setWrappingFee(wrappingFee);

      window.ethereum.request({ method: "eth_chainId" }).then((currentId: string) => {
        setEvmNetworkChosen(currentId);
      });

      // Reload page when network changes
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      // Fetch current account from Metamask when changed
      window.ethereum.on("accountsChanged", () => {
        loadAccount(provider);
      });
    } else {
      console.error("MetaMask is not installed.");
    }
  };

  useEffect(() => {
    connectMyAlgo();
    connectMetamaskProvider();
    initAppVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Web3Context.Provider
      value={{
        ethProvider,
        setEthProvider,
        algoConnect,
        algodClient,
        setAlgodClient,
        wrappingFee,
        loadAccount,
        evmNetworkChosen,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3Providers = (): AppContextType => {
  const context = useContext(Web3Context);

  if (context === undefined) {
    throw new Error("useWeb3Providers must be used within a Web3Providers");
  }

  return context;
};
