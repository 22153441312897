export type AlgorandTransaction = {};

export enum A1Networks {
  Devnet = "devnet",
  Mainnet = "mainnet",
  Localnet = "localnet",
}

export type RequestStatus = {
  executed_block_number: number | null;
  executed_timestamp: number | null;
  txid: string | null;
  votes: number | null;
};

export type A1NetworkInfo = Record<A1Networks, Network>;
export interface Configs {
  appVersion: string;
  a1: A1NetworkInfo;
}

export interface Network {
  name: string;
  common: Common;
  algorand: Algorand;
  evm: Evm;
  explorers: Explorers;
}

export interface Algorand {
  stargateAddress: string;
  algodURL: string;

  // value has to be taken from the indexer api endpoint, however if api is unavailable for some reason, let's have some default value here
  defaultServiceFee: number;

  assetLogoBaseUrl: string;
}

export interface Common {
  indexerURL: string;
  balanceUpdateTimeMS: number;
  milkomedaQuorum: number;

  // value has to be taken from the indexer api endpoint, however if api is unavailable for some reason, let's have some default value here
  defaultMinWrappingAmount: string;
}

export interface Evm {
  chainID: string;
  rpcURL: string;
  chainName: string;
  gasLimit: string;
  contractAddress: string;
}

export interface Explorers {
  algorand: string;
  evm: string;
  milkomeda: string;
}

export interface ContractInitialParams {
  protocol_magic: string;
  stargate_address: string;
  bridge_parameters: string;
  min_wmain_amount: string;
  timestamp: number;
  block_number: number;
  transaction_id: string;
}

export interface ExplorerStatistics {
  avg_24h_wrap_in_sec?: string;
  avg_24h_unwrap_in_sec?: string;
  last_24h_wraps: number;
  last_24h_unwraps: number;
  total_wraps: number;
  total_unwraps: number;
  total_ada_locked: string;
}
