import { microToAlgo } from "./utils";
import {
  A1_WrappingFee,
  AlgorandWallet,
  AlgorandWalletsProviders,
  Asset,
  DirectionNetworks,
  FEE_STRATEGIES,
  Networks,
  SourceDirections,
  UnwrappingFee,
} from "../types/wallet";

export const getFeeByStrategy = (
  strategy: FEE_STRATEGIES
): A1_WrappingFee | UnwrappingFee => {
  switch (strategy) {
    case FEE_STRATEGIES.A1_WRAPPING: {
      const FEE = parseInt("100000");
      const a1_wrapping: A1_WrappingFee = {
        serviceFee: microToAlgo(FEE.toString()),
        minimumReserveAmount: "0.1",
        algorandAssetsAmount: "0",
        networkFee: "0.001",
      };
      return a1_wrapping;
    }
    // case FEE_STRATEGIES.C1_WRAPPING: {
    //     break;
    // }
    // every other case is unwrapping
    default: {
      const unwrapping: UnwrappingFee = {
        constantValue: undefined,
        scMethod: "UNWRAPPING_FEE",
      };
      return unwrapping;
    }
  }
};

// Wrapping fee for Cardano
export const C1_WrappingFee = {
  // serviceFee: microToAlgo("1000000"),
  // minimumReserveAmount: "0.1",
  // algorandAssetsAmount: "0",
  // networkFee: "0.001",
};

export const AlgorandWallets: AlgorandWallet[] = [
  {
    provider: AlgorandWalletsProviders.MyAlgo,
    icon: "/media/logos/logo-myalgo.png",
    fullName: "MyAlgo Wallet",
  },
  {
    provider: AlgorandWalletsProviders.PeraWallet,
    icon: "/media/logos/logo-perawallet.svg",
    fullName: "Pera Wallet",
  },
];

export const MAIN_ASSET_ID =
  "0000000000000000000000000000000000000000000000000000000000000000";

export const algoAsset: Asset = {
  assetId: MAIN_ASSET_ID,
  id: MAIN_ASSET_ID,
  name: "Algorand (ALGO)",
  symbol: "ALGO",
  network: Networks.Algorand,
  icon: "/media/logos/asset-selection/algorand.svg",
};

export const milkomedaAsset: Asset = {
  assetId: MAIN_ASSET_ID,
  id: MAIN_ASSET_ID,
  name: "Milkomeda (mALGO)",
  symbol: "mALGO",
  network: Networks.Milkomeda,
  icon: "/media/logos/asset-selection/milkomeda.svg",
};

export const assetList: Asset[] = [algoAsset, milkomedaAsset];

export const directionsList: DirectionNetworks[] = [
  {
    sourceDirection: SourceDirections.Algorand,
    endDirection: SourceDirections.MilkomedaA1,
    sourceIcon: "/media/logos/asset-selection/algorand.svg",
    endIcon: "/media/logos/asset-selection/milkomeda.svg",
  },
  {
    sourceDirection: SourceDirections.MilkomedaA1,
    endDirection: SourceDirections.Algorand,
    sourceIcon: "/media/logos/asset-selection/milkomeda.svg",
    endIcon: "/media/logos/asset-selection/algorand.svg",
  },
];

export const microAlgo = 1_000_000;
export const MILKOMEDA_CONFIRMATION_REQUEST_INTERVAL = 3000; // ms

/// PREFIX description (milkomeda/a1:u)
/// milkomeda/a1 - prefix
/// u - means unicode string data
export const NOTE_PREFIX = "milkomeda/a1:u";

export const ALGORAND_ASSET_IMAGE_URL =
  "https://testnet.assets.algoexplorer.io/asset-logo-";
