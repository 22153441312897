import ReactDOM from "react-dom/client";
import App from "./App";
import BigNumber from "bignumber.js";

BigNumber.config({
  EXPONENTIAL_AT: 1e9, // max at 10^(1e9)
  DECIMAL_PLACES: 80, // slightly higher than log_10(2^256)
});

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
