import { PeraWalletConnect } from "@perawallet/connect";
import algosdk from "algosdk";
import BigNumber from "bignumber.js";
import AlgorandHandler from ".";
import { getFeeByStrategy, MAIN_ASSET_ID } from "../../constants";
import {
  A1_WrappingFee,
  AccountDetails,
  CustomAsset,
  FEE_STRATEGIES,
} from "../../../types/wallet";
import {
  algoToMicro,
  encodeNote,
  isCorrectAlgorandAddress,
  microToAlgo,
  prepareNoteForEncoding,
} from "../../utils";

class PeraWallet {
  public static PeraInstance: PeraWalletConnect = new PeraWalletConnect({
    shouldShowSignTxnToast: true,
  });

  private static setupPeraWallet = async (
    peraWallet: PeraWalletConnect,
    algodUrl: string,
    algodApiKey: string,
    newAccounts: string[],
    setAccount: any,
    selectedAsset?: CustomAsset
  ) => {
    // Setup the disconnect event listener
    peraWallet.connector?.on("disconnect", () => {
      console.log("Perawallet disconnected");
    });

    const algodClient = AlgorandHandler.getAlgodClient(algodUrl, algodApiKey);
    try {
      const account = await AlgorandHandler.getAlgorandAccountDetails(
        algodClient,
        newAccounts[0],
        selectedAsset
      );
      setAccount(account);
    } catch (e) {
      const err = e as Error;
      console.log(err.message);
      // balance = null;
    }
  };

  public static connect = async (
    algodUrl: string,
    algodApiKey: string,
    setAccount: React.Dispatch<React.SetStateAction<AccountDetails | undefined>>,
    setAlgoWalletConnected: any
  ): Promise<boolean> => {
    let connected: boolean = false;
    try {
      PeraWallet.PeraInstance = new PeraWalletConnect({ shouldShowSignTxnToast: true });
      setAlgoWalletConnected(PeraWallet.PeraInstance);
      const newAccounts = await PeraWallet.PeraInstance.connect();
      await PeraWallet.setupPeraWallet(
        PeraWallet.PeraInstance,
        algodUrl,
        algodApiKey,
        newAccounts,
        setAccount
      );
      connected = true;
    } catch (e) {
      // we enter this catch most likely when user closes the modal without pursuing any interaction
      // PeraWallet does not support any error handling at this moment (docs show that catching error is the most preferable way)
      // console.error(e);

      // ensure no PeraWallet localstorage items are anywhere in the case modal is closed (no specific error handling for this at the moment, so we need to do it manually)
      if (localStorage.getItem("PeraWallet.BridgeURL")) {
        localStorage.removeItem("PeraWallet.BridgeURL");
      }
    }
    return connected;
  };

  public static async handleDisconnectWalletClick() {
    try {
      await PeraWallet.PeraInstance.disconnect();
    } catch (e) {
      console.log(e);
    }
  }

  public static async reconnect(
    algodUrl: string,
    algodApiKey: string,
    setAccount: React.Dispatch<React.SetStateAction<AccountDetails | undefined>>,
    setAlgoWalletConnected: any,
    selectedAsset?: CustomAsset
  ) {
    try {
      const accounts = await PeraWallet.PeraInstance.reconnectSession();
      if (accounts.length === 0) {
        throw new Error("Session needs to be initialized.");
      }
      setAlgoWalletConnected(PeraWallet.PeraInstance);
      await PeraWallet.setupPeraWallet(
        PeraWallet.PeraInstance,
        algodUrl,
        algodApiKey,
        accounts,
        setAccount,
        selectedAsset
      );
      return true;
    } catch (e) {
      // console.log(e);
      return await PeraWallet.connect(
        algodUrl,
        algodApiKey,
        setAccount,
        setAlgoWalletConnected
      );
    }
  }

  public static calculateFees = async (
    algodClient: algosdk.Algodv2 | null,
    fee: number
  ): Promise<A1_WrappingFee> => {
    const serviceFee = new BigNumber(microToAlgo(fee.toString()));
    const minimumReserveAmount = new BigNumber(0.1);
    const algorandAssetsAmount = new BigNumber(0.1 * 0); // for now it'll be 0, since now algorand assets are being sent except for the ALGOs
    let networkFee = 0.001;
    if (algodClient) {
      const params = await algodClient.getTransactionParams().do();
      if (params) {
        networkFee = params.fee === 0 ? networkFee : params.fee;
      }
    }

    const fees: A1_WrappingFee = {
      serviceFee: serviceFee.toString(),
      minimumReserveAmount: minimumReserveAmount.toString(),
      algorandAssetsAmount: algorandAssetsAmount.toString(),
      networkFee: networkFee.toString(),
    };
    return fees;
  };

  public static signAndSendWrappingAlgorand = async (
    algodClient: algosdk.Algodv2 | null,
    algoConnect: PeraWalletConnect | null,
    stargateAddress: string,
    originAccount: AccountDetails,
    destinationAccount: AccountDetails,
    sendAmountWithFees: string | undefined,
    sendAmount: string | null,
    setAlgoTx: any
  ): Promise<string | null> => {
    try {
      if (
        !algodClient ||
        !PeraWallet.PeraInstance ||
        !sendAmountWithFees ||
        originAccount === undefined ||
        sendAmount === undefined ||
        !isCorrectAlgorandAddress(originAccount?.address)
      )
        return null;

      const algorandFees = getFeeByStrategy(FEE_STRATEGIES.A1_WRAPPING) as A1_WrappingFee;

      const params = await algodClient.getTransactionParams().do();
      let unsignedTx: algosdk.Transaction | null = null;
      if (originAccount.currentAsset?.id === MAIN_ASSET_ID) {
        const amount = new BigNumber(sendAmountWithFees)
          .minus(new BigNumber(algorandFees.networkFee))
          .toString();
        unsignedTx = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
          suggestedParams: {
            ...params,
          },
          from: originAccount?.address,
          to: stargateAddress ?? "",
          amount: parseInt(algoToMicro(amount)),
          note: encodeNote(prepareNoteForEncoding(destinationAccount?.address)),
        });
      } else {
        unsignedTx = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: {
            ...params,
          },
          from: originAccount?.address,
          to: stargateAddress ?? "",
          amount: parseInt(
            AlgorandHandler.convertToDefaultValue(
              sendAmount as string,
              originAccount.currentAsset?.decimals as number
            )
          ),
          note: encodeNote(prepareNoteForEncoding(destinationAccount?.address)),
          assetIndex: Number(originAccount.currentAsset?.id),
        });
      }

      const singleTxnGroups = [{ txn: unsignedTx, signers: [originAccount.address] }];
      const signedTxn = await PeraWallet.PeraInstance.signTransaction([singleTxnGroups]);
      const { txId } = await algodClient.sendRawTransaction(signedTxn).do();
      setAlgoTx(txId);
      return txId as string;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  public static signAndSendTransaction = async (
    algodClient: algosdk.Algodv2,
    unsignedTx: algosdk.Transaction,
    signers: string[]
  ): Promise<string | null | Error> => {
    try {
      const singleTxnGroups = [{ txn: unsignedTx, signers: signers }];
      const signedTxn = await PeraWallet.PeraInstance.signTransaction([singleTxnGroups]);
      const { txId } = await algodClient.sendRawTransaction(signedTxn).do();
      return txId;
    } catch (err) {
      console.error(err);
      return err as Error;
    }
  };
}

export default PeraWallet;
