import MyAlgoConnect from "@randlabs/myalgo-connect";
import algosdk from "algosdk";
import BigNumber from "bignumber.js";
import AlgorandHandler from ".";
import { getFeeByStrategy, MAIN_ASSET_ID } from "../../constants";
import {
  algoToMicro,
  encodeNote,
  isCorrectAlgorandAddress,
  microToAlgo,
  prepareNoteForEncoding,
} from "../../utils";
import { A1_WrappingFee, AccountDetails, FEE_STRATEGIES } from "../../../types/wallet";
import { ethers } from "ethers";

class MyAlgo {
  public static connect = async (
    algodUrl: string,
    algodApiKey: string,
    setAccount: React.Dispatch<React.SetStateAction<AccountDetails | undefined>>,
    setAlgoWalletConnected: any
  ): Promise<boolean> => {
    try {
      let myAlgoConnect = new MyAlgoConnect();
      setAlgoWalletConnected(myAlgoConnect);
      let accountsSharedByUser = await myAlgoConnect.connect({
        shouldSelectOneAccount: true,
        openManager: true,
      });

      const algodClient = AlgorandHandler.getAlgodClient(algodUrl, algodApiKey);
      if (accountsSharedByUser.length > 0) {
        const originAccount = await AlgorandHandler.getAlgorandAccountDetails(
          algodClient,
          accountsSharedByUser[0].address
        );
        setAccount(originAccount);
        return true;
      }
    } catch (e) {
      const err = e as Error;
      console.log(
        `MyAlgo can not get account information. Please try again. ${err.message}`
      );
    }
    return false;
  };

  public static calculateFees = async (
    algodClient: algosdk.Algodv2 | null,
    fee: number
  ): Promise<A1_WrappingFee> => {
    const serviceFee = new BigNumber(microToAlgo(fee.toString()));
    const minimumReserveAmount = new BigNumber(0.1);
    const algorandAssetsAmount = new BigNumber(0.1 * 0); // for now it'll be 0, since now algorand assets are being sent except for the ALGOs
    let networkFee = 0.001;
    if (algodClient) {
      const params = await algodClient.getTransactionParams().do();
      if (params) {
        networkFee = params.fee === 0 ? networkFee : params.fee;
      }
    }

    const fees: A1_WrappingFee = {
      serviceFee: serviceFee.toString(),
      minimumReserveAmount: minimumReserveAmount.toString(),
      algorandAssetsAmount: algorandAssetsAmount.toString(),
      networkFee: networkFee.toString(),
    };
    return fees;
  };

  public static signAndSendWrappingAlgorand = async (
    algodClient: algosdk.Algodv2 | null,
    algoConnect: MyAlgoConnect | null,
    stargateAddress: string,
    originAccount: AccountDetails,
    destinationAccount: AccountDetails,
    sendAmountWithFees: string | undefined,
    sendAmount: string | null
  ): Promise<string | null> => {
    try {
      if (
        !algodClient ||
        !sendAmountWithFees ||
        originAccount === undefined ||
        sendAmount === undefined ||
        !isCorrectAlgorandAddress(originAccount?.address) ||
        !ethers.utils.isAddress(destinationAccount?.address)
      )
        return null;

      const algorandFees = getFeeByStrategy(FEE_STRATEGIES.A1_WRAPPING) as A1_WrappingFee;

      const params = await algodClient.getTransactionParams().do();
      // const amount = new BigNumber(sendAmountWithFees).minus(new BigNumber(algorandFees.networkFee)).toString();
      let unsignedTx: algosdk.Transaction | null = null;
      if (originAccount.currentAsset?.id === MAIN_ASSET_ID) {
        const amount = new BigNumber(sendAmountWithFees)
          .minus(new BigNumber(algorandFees.networkFee))
          .toString();
        unsignedTx = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
          suggestedParams: {
            ...params,
          },
          from: originAccount?.address,
          to: stargateAddress ?? "",
          amount: parseInt(algoToMicro(amount)),
          note: encodeNote(prepareNoteForEncoding(destinationAccount?.address)),
        });
      } else {
        unsignedTx = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: {
            ...params,
          },
          from: originAccount?.address,
          to: stargateAddress ?? "",
          amount: parseInt(
            AlgorandHandler.convertToDefaultValue(
              sendAmount as string,
              originAccount.currentAsset?.decimals as number
            )
          ),
          note: encodeNote(prepareNoteForEncoding(destinationAccount?.address)),
          assetIndex: Number(originAccount.currentAsset?.id),
        });
      }

      if (unsignedTx !== null) {
        algoConnect = new MyAlgoConnect();
        const signedTxn = await algoConnect.signTransaction(unsignedTx.toByte());
        const { txId } = await algodClient.sendRawTransaction(signedTxn.blob).do();
        return txId as string;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  public static signAndSendTransaction = async (
    algodClient: algosdk.Algodv2,
    unsignedTx: algosdk.Transaction
  ): Promise<string | null | Error> => {
    try {
      const algoConnect = new MyAlgoConnect();
      const signedTxn = await algoConnect.signTransaction(unsignedTx.toByte());
      const { txId } = await algodClient.sendRawTransaction(signedTxn.blob).do();
      return txId as string;
    } catch (err) {
      console.error(err);
      return err as Error;
    }
  };
}

export default MyAlgo;
