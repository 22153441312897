import React, { createContext, useContext, useState } from "react";
import useDappSettings, { HandleUpdateSettings } from "../hooks/useDappSettings";
import useLocalStorage from "../hooks/useLocalStorage";
import useLocalStorageWithExpiry from "../hooks/useLocalStorageWithExpiry";
import {
  AccountDetails,
  AlgorandWallet,
  DappSettings,
  SourceDirections,
} from "../types/wallet";
import { StorageKeys } from "../utils/storageKeys";

type AppContextType = {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isWrapping: boolean | undefined;
  dappSettings: DappSettings | undefined;
  handleUpdateDappSettings: HandleUpdateSettings;
  originAccount: AccountDetails | undefined;
  setOriginAccount: React.Dispatch<React.SetStateAction<AccountDetails | undefined>>;
  destinationAccount: AccountDetails | undefined;
  setDestinationAccount: React.Dispatch<React.SetStateAction<AccountDetails | undefined>>;
  selectedMainchainWallet: AlgorandWallet | null;
  setSelectedMainchainWallet: React.Dispatch<React.SetStateAction<AlgorandWallet | null>>;
};

const AppContext = createContext({} as AppContextType);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { dappSettings, handleUpdateDappSettings } = useDappSettings();

  const [originAccount, setOriginAccount] = useLocalStorageWithExpiry(
    StorageKeys.ORIGIN_ACCOUNT,
    undefined
  );
  const [destinationAccount, setDestinationAccount] = useLocalStorageWithExpiry(
    StorageKeys.DESTINATION_ACCOUNT,
    undefined
  );
  const [selectedMainchainWallet, setSelectedMainchainWallet] = useLocalStorage(
    StorageKeys.SELECTED_MAINCHAIN_WALLET,
    undefined
  );

  const isWrapping = dappSettings?.direction.sourceDirection
    ? dappSettings.direction.sourceDirection === SourceDirections.Algorand
    : undefined;

  return (
    <AppContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        dappSettings,
        handleUpdateDappSettings,
        isWrapping,
        originAccount,
        setOriginAccount,
        destinationAccount,
        setDestinationAccount,
        selectedMainchainWallet,
        setSelectedMainchainWallet,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppProvider = (): AppContextType => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppProvider must be used within a AppProvider");
  }

  return context;
};
