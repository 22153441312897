import { StorageKeys } from "./storageKeys";
import config from "../_config/config.json";

const APP_VERSION = config.appVersion ?? "0.0.0";

export const initAppVersion = async () => {
  const userAppVersion = localStorage.getItem(StorageKeys.APP_VERSION);
  if (userAppVersion == null || userAppVersion !== APP_VERSION) {
    localStorage.clear();
    localStorage.setItem(StorageKeys.APP_VERSION, APP_VERSION);
  }
};
