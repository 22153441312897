import styled from "@emotion/styled";
import * as Dialog from "@radix-ui/react-dialog";

export const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background: #1a1a1c;
  opacity: 0.8;
`;
//animation
DialogOverlay.defaultProps = { className: "dialog-overlay" };

export const DialogContent = styled(Dialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  min-height: 200px;
  background: #333337;
  border-radius: 4px;
  color: white;
  padding: 16px;
  @media (max-width: 768px) {
    min-width: auto;
    width: 100%;
  }
`;
DialogContent.defaultProps = { className: "dialog-content" };

export const DialogAlertContent = styled(Dialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  min-height: 288px;
  background: #333337;
  border-radius: 4px;
  color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  padding-top: 47px;
`;
DialogAlertContent.defaultProps = { className: "dialog-content" };
