import * as React from "react";
import { useNavigate } from "react-router-dom";
import { networkList } from "../App";
import { useNetwork } from "../context/NetworkContext";
import { useSelect } from "downshift";
import NetworkModal from "./Modal/NetworkModal";
import styled from "@emotion/styled";
import { ReactComponent as ArrowDownIcon } from "../assets/icons/dropdown-icon.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/check-icon-selection.svg";
import Evm from "../services/EVM";

function itemToString(item) {
  return item ? item.name : "";
}

export default function NetworkSelect({ onNetworkConfirmed }) {
  const [isConfirmNetworkModal, setConfirmNetworkModal] = React.useState<boolean>(false);
  const networkRef = React.useRef<any>();

  const navigate = useNavigate();
  const { selectedNetwork, setSelectedNetwork } = useNetwork();

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items: networkList,
    itemToString,
    initialSelectedItem: selectedNetwork,
    onSelectedItemChange: ({ selectedItem }) => {
      networkRef.current = selectedItem;
      return;
    },
  });

  return (
    <>
      <SelectWrapper className="networkSelect">
        <label className="sr-only" {...getLabelProps()}>
          Choose network:
        </label>
        <SelectButton aria-label="toggle menu" {...getToggleButtonProps()}>
          <DotIcon isActive></DotIcon>
          <span className="label">
            {selectedNetwork ? selectedNetwork.name : "Select Network"}
          </span>
          <SArrowDownIcon open={isOpen} />
        </SelectButton>
        <DropdownContent {...getMenuProps()}>
          {isOpen &&
            networkList.map((item, index) => (
              <ListItem
                isHighlight={highlightedIndex === index}
                isSelected={selectedItem === item}
                key={`${item.name}${index}`}
                {...getItemProps({
                  item,
                  index,
                  onClick: () => {
                    setConfirmNetworkModal(true);
                  },
                })}
              >
                <Flex>
                  <DotIcon isActive={selectedItem === item} />
                  <span>{item.name}</span>
                </Flex>
                {selectedItem === item && <SCheckIcon />}
              </ListItem>
            ))}
        </DropdownContent>
      </SelectWrapper>
      {/* Change Network Modal */}
      <NetworkModal
        isOpen={isConfirmNetworkModal}
        onOpenChange={setConfirmNetworkModal}
        onCancel={() => {
          networkRef.current = null;
          selectItem(selectedNetwork);
          setConfirmNetworkModal(false);
        }}
        onChangeNetwork={async () => {
          if (
            networkRef.current == null ||
            networkRef?.current?.name === selectedNetwork.name
          ) {
            setConfirmNetworkModal(false);
            return;
          }
          selectItem(networkRef.current);
          setSelectedNetwork(networkRef?.current);

          await Evm.switchEthChain({
            chainId: networkRef?.current?.evm.chainID,
            evmServerUrl: networkRef?.current?.evm.rpcURL,
            evmChainName: networkRef?.current?.evm.chainName,
          });
          navigate(`/${networkRef?.current.name}`);
          setConfirmNetworkModal(false);
          onNetworkConfirmed();
        }}
      />
    </>
  );
}

const SCheckIcon = styled(CheckIcon)`
  color: white;
`;
const SArrowDownIcon = styled(ArrowDownIcon)<{ open: boolean }>`
  transform: ${(props) => (props.open ? "rotate(180deg)" : "")};
`;
const SelectWrapper = styled.div`
  position: relative;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const SelectButton = styled.button`
  display: flex;
  align-items: center;
  padding: 11px 12px;
  background: #333337;
  color: white;
  justify-content: space-between;
  min-width: 178px;
  height: 44px;
  font-weight: 600;
  border-radius: 4px;
  transition: background 0.2s ease-in-out;
  &:hover {
    background: #444449;
  }
  .label {
    margin-left: 0;
    font-size: 1rem;
    flex: 1;
    text-align: left;
    text-transform: capitalize;
  }
`;
const DropdownContent = styled.ul`
  position: absolute;
  margin-top: 4px;
  z-index: 1000;
  left: 0;
  right: 0;
  max-height: 400px;
  background: #333337;
  border-radius: 4px;
  /* padding-left: 0; */
`;
const ListItem = styled.li<{ isHighlight: boolean; isSelected: boolean }>`
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  padding: 9px 12px;
  color: ${(props) =>
    props.isHighlight ? "white" : props.isSelected ? "white" : "#b5b0b0"};
  text-transform: capitalize;
  cursor: pointer;
`;
const DotIcon = styled.div<{ isActive?: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${(props) => (props.isActive ? "#f07d00" : "#868686")};
  margin-right: 12px;
`;
