import * as Dialog from "@radix-ui/react-dialog";
import { DialogAlertContent, DialogOverlay } from "./Modal";
import { ReactComponent as NetworkChangeIcon } from "../../assets/icons/network-change.svg";
import styled from "@emotion/styled";
import { OutlineButton, PrimaryButton } from "../../styles/GlobalStyles";

const NetworkModal = ({ isOpen, onOpenChange, onCancel, onChangeNetwork }) => {
  return (
    <Dialog.Root modal open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogAlertContent>
          <NetworkChangeIcon />
          <Title>You are about to change the network!</Title>
          <Description>
            You will have to reconnect your mainchain wallet to proceed the assets
            transfer on a chosen network.
          </Description>
          <SeparatorLine />
          <Footer>
            <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
            <PrimaryButton onClick={onChangeNetwork}>Yes, change network</PrimaryButton>
          </Footer>
        </DialogAlertContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NetworkModal;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 4px;
`;

const Description = styled.p`
  text-align: center;
  font-size: 0.75rem;
`;
const SeparatorLine = styled.hr`
  border-top: 1px solid #868686;
  margin: 24px 0;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  button + button {
    margin-left: 20px;
  }
`;
