import { PeraWalletConnect } from "@perawallet/connect";
import MyAlgoConnect, { Accounts } from "@randlabs/myalgo-connect";
import algosdk from "algosdk";

export type DappSettings = {
  direction: DirectionNetworks;
  selectedToken: CustomAsset;
  fees: FEE_STRATEGIES;
  originAccount: AccountSettings;
  destinationAccount: AccountSettings;
};

export type AccountSettings = {
  details: AccountDetails | undefined;
  network: Networks;
  icon: string;
};

export type UnwrappingFee = {
  constantValue?: undefined;
  scMethod?: "UNWRAPPING_FEE";
};

export enum FEE_STRATEGIES {
  A1_WRAPPING,
  A1_UNWRAPPING,
  C1_WRAPPING,
  C1_UNWRAPPING,
}

// Wrapping fee for Algorand
export type A1_WrappingFee = {
  serviceFee: string;
  minimumReserveAmount: string;
  algorandAssetsAmount: string;
  networkFee: string;
};

export enum AlgorandWalletsProviders {
  MyAlgo = "MyAlgo",
  PeraWallet = "PeraWallet",
}

export type AlgorandWallet = {
  provider: AlgorandWalletsProviders;
  icon: string;
  fullName: string;
};

export type Asset = {
  id: string;
  name: string;
  symbol: string;
  network: Networks;
  icon: string;
  assetId?: string;
};

export enum Networks {
  Milkomeda = "Milkomeda A1",
  Algorand = "Algorand",
}

export interface AlgoSetup {
  myAlgoConnect: MyAlgoConnect;
  accountsSharedByUser: Accounts[];
  algodClient: algosdk.Algodv2;
}

export interface AccountDetails {
  address: string;
  assets: CustomAsset[];
  currentAsset: CustomAsset;
  minAccountBalance?: string;
}

export type CustomAsset = {
  assetId: string;
  id: string;
  symbol: string;
  decimals?: number;
  icon: string;
  balance: string;
  tokenContract?: string;
};

export type Fees = {
  serviceFee: string;
  minimumReserveAmount: string;
  algorandAssetsAmount: string;
  networkFee: string;
};

export type MainchainWalletConnectors = MyAlgoConnect | PeraWalletConnect;

export type IMainchainOptions = IAlgorandOptions;

export interface IAlgorandOptions {
  algorand?: {
    algoAsset?: Asset;
    algodClient?: algosdk.Algodv2 | null;
  };
}

export enum SourceDirections {
  Algorand = "Algorand",
  MilkomedaA1 = "Milkomeda",
  // Cardano = "Cardano - Milkomeda",
  // MilkomedaC1 = "Milkomeda - Cardano",
}

export type DirectionNetworks = {
  sourceDirection: SourceDirections;
  sourceIcon: string;
  endDirection: SourceDirections;
  endIcon: string;
};

export type TokenRegistryEntry = {
  asset_id: string;
  cardano_fingerprint: string | null;
  cardano_policy: string | null;
  mainchain_decimals: number | null;
  minimum_value: "10000000000";
  sidechain_decimals: number | null;
  symbol: string | null;
  timestamp: number;
  token_contract: string;
  token_type: string;
};
