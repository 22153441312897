import { ReactComponent as InfoIcon } from "../assets/icons/info.svg";
import * as Tooltip from "@radix-ui/react-tooltip";
import styled from "@emotion/styled";

const TooltipWithLabel = (props: {
  label?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div {...props}>
      {props.label}
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <Tooltip.Trigger>
            <SInfoIcon />
          </Tooltip.Trigger>
          <TooltipContent sideOffset={5}>
            <TooltipArrow />
            {props.children}
          </TooltipContent>
        </Tooltip.Root>
      </Tooltip.Provider>
    </div>
  );
};

export default TooltipWithLabel;

const SInfoIcon = styled(InfoIcon)`
  width: 20px;
`;
const TooltipArrow = styled(Tooltip.Arrow)`
  fill: white;
`;
const TooltipContent = styled(Tooltip.Content)`
  background: white;
  font-size: 0.75rem;
  border-radius: 4px;
  max-width: 200px;
  padding: 10px 20px;
  color: #1c1c1c;
  z-index: 9999;
`;
