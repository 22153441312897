import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";

const GlobalStyles = () => (
  <Global
    styles={css`
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-thumb {
        background: rgba(162, 187, 255, 0.1);
        border-radius: 10px;
        height: 50px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: none;
        background: #222222;
      }

      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      *,
      ::before,
      ::after {
        box-sizing: border-box; /* 1 */
        border-width: 0; /* 2 */
        border-style: solid; /* 2 */
        border-color: currentColor;
      }

      html {
        line-height: 1.5; /* 1 */
        -webkit-text-size-adjust: 100%; /* 2 */
      }

      body {
        margin: 0; /* 1 */
        line-height: inherit; /* 2 */
        font-family: "Manrope", sans-serif;
      }

      hr {
        height: 0; /* 1 */
        color: inherit; /* 2 */
        border-top-width: 1px; /* 3 */
      }

      abbr:where([title]) {
        text-decoration: underline dotted;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: inherit;
        font-weight: inherit;
      }

      a {
        color: inherit;
        text-decoration: inherit;
      }

      b,
      strong {
        font-weight: bolder;
      }

      code,
      kbd,
      samp,
      pre {
        font-family: monospace;
        font-size: 1em; /* 2 */
      }

      small {
        font-size: 80%;
      }

      sub,
      sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
      }

      sub {
        bottom: -0.25em;
      }

      sup {
        top: -0.5em;
      }

      table {
        text-indent: 0; /* 1 */
        border-color: inherit; /* 2 */
        border-collapse: collapse; /* 3 */
      }

      button,
      input,
      optgroup,
      select,
      textarea {
        font-family: inherit;
        font-size: 100%;
        font-weight: inherit;
        line-height: inherit;
        color: inherit;
        margin: 0;
        padding: 0;
      }

      button,
      select {
        text-transform: none;
      }

      button,
      [type="button"],
      [type="reset"],
      [type="submit"] {
        -webkit-appearance: button;
        background-color: transparent;
        background-image: none;
      }

      /*
Add the correct vertical alignment in Chrome and Firefox.
*/

      progress {
        vertical-align: baseline;
      }

      /*
Correct the cursor style of increment and decrement buttons in Safari.
*/

      [type="search"] {
        -webkit-appearance: textfield; /* 1 */
        outline-offset: -2px; /* 2 */
      }

      summary {
        display: list-item;
      }

      /*
Removes the default spacing and border for appropriate elements.
*/

      blockquote,
      dl,
      dd,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      hr,
      figure,
      p,
      pre {
        margin: 0;
      }

      fieldset {
        margin: 0;
        padding: 0;
      }

      legend {
        padding: 0;
      }

      ol,
      ul,
      menu {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      textarea {
        resize: vertical;
      }

      input::placeholder,
      textarea::placeholder {
        opacity: 1; /* 1 */
        color: theme("colors.gray.400", #9ca3af); /* 2 */
      }

      button,
      [role="button"] {
        cursor: pointer;
      }

      img,
      svg,
      video,
      canvas,
      audio,
      iframe,
      embed,
      object {
        display: block; /* 1 */
        vertical-align: middle; /* 2 */
      }

      img,
      video {
        max-width: 100%;
        height: auto;
      }

      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }
      /* Dialog animation */
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      .dialog-overlay[data-state="open"] {
        animation: fadeIn 50ms ease-out;
      }
      .dialog-content[data-state="open"] {
        animation: fadeIn 200ms ease-out;
      }

      .dialog-overlay[data-state="closed"] {
        animation: fadeOut 100ms ease-in;
      }
      .dialog-content[data-state="closed"] {
        animation: fadeOut 50ms ease-in;
      }

      /* Other */

      .pera-wallet-connect-modal {
        /* The default value of z-index is 10. You can lower and raise it as much as you want. */
        z-index: 11;
      }

      /* Animation spinner */
      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
      .animate-spin {
        animation: spin 2s ease-in-out infinite;
      }
    `}
  />
);

export default GlobalStyles;

export const GradientCard = styled.div`
  background: linear-gradient(256.89deg, #4a403f 18.89%, #594533 81.11%);
  border-radius: 4px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PrimaryButton = styled.button`
  background: #f07d00;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  transition: background 0.3s ease-out;
  &:hover {
    background: #f29126;
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
    background: #f07d00;
  }
`;
export const OutlineButton = styled.button`
  background: #333337;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  transition: border 0.3s ease-out;
  border: 1px solid #868686;

  &:hover {
    border: 1px solid white;
  }
  &:disabled {
    opacity: 0.4;
    border: 1px solid #868686;
    cursor: default;
  }
`;

export const PrimaryLink = styled.a<{ disabled?: boolean }>`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${(props) => (props.disabled ? "#827A76" : "#ff9828")};
  height: 40px;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${(props) => (props.disabled ? "#827A76" : "#f29126")};
  }
  pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;
