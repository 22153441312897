import { Web3Provider } from "@ethersproject/providers";
import algosdk from "algosdk";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { fromWei } from "web3-utils";
import { algoAsset, microAlgo, NOTE_PREFIX } from "./constants";
import { Asset } from "../types/wallet";
import moment from "moment";

export const isMilkALGO = (asset: Asset): boolean => asset.id === "mALGO";
export const truncateAddress = (address: string): string => {
  return `${address.substring(0, 6)}..${address.substring(
    address.length - 5,
    address.length
  )}`;
};

// algorand note helpers
export function decodeNote(encodedNote: any): string {
  return Buffer.from(encodedNote, "base64").toString();
}

export function encodeNote(note: string): Uint8Array {
  return new TextEncoder().encode(note);
}

export const microToAlgo = (value: string): string => {
  return new BigNumber(value).div(new BigNumber(microAlgo)).toString();
};

export const algoToMicro = (value: string): string => {
  return new BigNumber(value).multipliedBy(new BigNumber(microAlgo)).toString();
};

export const isValidStr = (value: string | undefined | null): boolean => {
  if (!value || value.length === 0) return false;
  return true;
};

export const prepareNoteForEncoding = (note: string): string => `${NOTE_PREFIX}${note}`;

export const isCorrectEvmNetworkChosen = (
  evmNetworkChosen: string | undefined,
  evmChainId: string
) => {
  return evmNetworkChosen?.toLowerCase() === evmChainId.toLowerCase();
};

export const isCorrectBridgeAddress = (stargateAddress: string) => {
  try {
    algosdk.decodeAddress(stargateAddress as string);
    return true;
  } catch (e) {
    const err = e as Error;
    console.log(`Incorrect bridge address provided ${stargateAddress}.${err.message}`);
    return false;
  }
};

export const isCorrectAlgorandAddress = (address: string) => {
  try {
    algosdk.decodeAddress(address);
    return true;
  } catch (e) {
    return false;
  }
};

export const getMaxAmountDetails = (
  isWrapping: boolean,
  maxPossibleSendAmount: string
): string => {
  if (isWrapping) {
    return `Your max wrapping amount is ${maxPossibleSendAmount}.\n It's decreased by the service fee, network fee & min reserve for Algorand account.`;
  } else {
    return `Your max unwrapping amount is ${maxPossibleSendAmount}.\n It's decreased by the estimated unwrapping fee.`;
  }
};

export const getWrappingFee = async (
  provider: Web3Provider,
  contractAbi: any,
  contractAddressEVM: string,
  serviceFee: string
): Promise<string> => {
  try {
    const bridgeContract = new ethers.Contract(contractAddressEVM, contractAbi, provider);
    const feeFromSc = await bridgeContract.functions.WRAPPING_FEE();
    return fromWei(new BigNumber(feeFromSc).toString());
  } catch (e) {
    console.error(e);
    return serviceFee;
  }
};

// todo: create separate layer for all requests related to the bridge indexer and move it there
// export const getContractInitialSettings = async (): Promise<ContractInitialParams | Error> => {
//     try {
//         const response = await fetch(`${MILKOMEDA_INDEXER_URL}/contract_initial_params`);
//         const results = (await response.json()) as ContractInitialParams[];
//         if (results.length === 0) {
//             throw Error("Api didn't find proper value for initial params.");
//         }

//         // there's always only one row
//         return results[0];
//     } catch (e) {
//         return e as Error;
//     }
// };

export const mapAssetWithDecimalsAndSymbols = (
  assetId: string
): { symbol: string; decimals: number } => {
  switch (assetId) {
    case "12400859": {
      return {
        symbol: "EURe",
        decimals: 8,
      };
    }
    default: {
      return {
        symbol: "ALGO",
        decimals: 6,
      };
    }
  }
};

export const informAboutEstimatedFee = (
  isWrapping: boolean,
  feeAmount: string,
  symbol: string
): string => {
  let feeInformation = `~ ${feeAmount ?? "0.101"} ${symbol}`;
  if (!isWrapping && symbol !== algoAsset.symbol) {
    feeInformation = `~ 1 mALGO`;
  } else {
    feeInformation = `~ ${feeAmount ?? "0.101"} ${symbol}`;
  }
  return feeInformation;
};

export const onErrorAssetSymbolLogo = (
  e: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  e.currentTarget.src = "/media/logos/asset-selection/default-token.svg";
};

export const secondsToMinutesAndSeconds = (seconds: string | number) => {
  let value = seconds;
  if (typeof value === "string") {
    value = parseInt(value);
  }
  const secondsMoment = moment.unix(value);
  const minute = secondsMoment.format("mm");
  const second = secondsMoment.format("ss");
  return `${minute}m ${second}s`;
};
