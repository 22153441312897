/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useState } from "react";
import { Network, A1Networks } from "../types/types";
import config from "../_config/config.json";

type NetworkVars = {
  EVM_CHAIN_ID: string;
  EVM_SERVER_URL: string;
  EVM_CHAIN_NAME: string;
  EXPLORER_ALGO: string;
  EXPLORER_A1_EVM: string;
  ALGORAND_BALANCE_CHECK_MS: number;
  STARGATE_ADDRESS_A1: string;
  CONTRACT_ADDRESS_A1_EVM: string;
  MILKOMEDA_INDEXER_URL: string;
  MILKOMEDA_QUORUM: number;
  ALGORAND_SERVICE_FEE: string;
  EVM_GAS_LIMIT: string;
  MIN_WRAPPING_AMOUNT: string;
  ALGOD_URL: string;
  ALGOD_API_KEY: string;
};
type NetworkContext = {
  selectedNetwork: Network;
  setSelectedNetwork: React.Dispatch<React.SetStateAction<Network>>;
} & NetworkVars;

const AppContext = createContext({} as NetworkContext);

export const NetworkProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedNetwork, setSelectedNetwork] = useState<Network>(() => {
    const [_, network] = window.location.pathname.split("/");
    const isValidNetwork = Object.keys(config.a1).find((item) => item === network);
    return isValidNetwork
      ? config.a1[network as A1Networks]
      : config.a1[A1Networks.Mainnet];
  });

  const EVM_CHAIN_ID = ((): string => {
    const chainId = selectedNetwork.evm.chainID;
    if (chainId === undefined) {
      console.error(
        `EVM_CHAIN_ID not specified: ${chainId}. Please set proper value and restart the app.`
      );
      return "";
    }
    return chainId;
  })();

  const EVM_SERVER_URL = ((): string => {
    const serverUrl = selectedNetwork.evm.rpcURL;
    if (serverUrl === undefined) {
      console.error(
        `EVM_SERVER_URL not specified: ${serverUrl}. Please set proper value and restart the app.`
      );
      return "";
    }
    return serverUrl;
  })();

  const EVM_CHAIN_NAME = ((): string => {
    const chainName = selectedNetwork.evm.chainName;
    if (chainName === undefined) {
      console.error(
        `EVM_CHAIN_NAME not specified: ${chainName}. Please set proper value and restart the app.`
      );
      return "";
    }
    return chainName;
  })();

  const EXPLORER_ALGO = ((): string => {
    const chainName = selectedNetwork.explorers.algorand;
    if (chainName === undefined) {
      console.error(
        `EXPLORER_ALGO not specified: ${chainName}. Please set proper value and restart the app.`
      );
      return "";
    }
    return chainName;
  })();

  const EXPLORER_A1_EVM = ((): string => {
    const chainName = selectedNetwork.explorers.evm;
    if (chainName === undefined) {
      console.error(
        `EXPLORER_A1_EVM not specified: ${chainName}. Please set proper value and restart the app.`
      );
      return "";
    }
    return chainName;
  })();

  const ALGORAND_BALANCE_CHECK_MS = ((): number => {
    const balanceCheckMs = selectedNetwork.common.balanceUpdateTimeMS;
    if (balanceCheckMs === undefined) {
      return 7000;
    }
    return balanceCheckMs as unknown as number;
  })();

  const STARGATE_ADDRESS_A1 = ((): string => {
    const chainName = selectedNetwork.algorand.stargateAddress;
    if (chainName === undefined) {
      console.error(
        `STARGATE_ADDRESS_A1 not specified: ${chainName}. Please set proper value and restart the app.`
      );
      return "";
    }
    return chainName;
  })();

  const CONTRACT_ADDRESS_A1_EVM = ((): string => {
    const chainName = selectedNetwork.evm.contractAddress;
    if (chainName === undefined) {
      console.error(
        `CONTRACT_ADDRESS_A1_EVM not specified: ${chainName}. Please set proper value and restart the app.`
      );
      return "";
    }
    return chainName;
  })();

  const MILKOMEDA_INDEXER_URL = ((): string => {
    const url = selectedNetwork.common.indexerURL;
    if (url === undefined) {
      console.error(
        "Incorrect Milkomeda indexer url. Please provide proper one, to be able to see mainchain confirmations when performing unwrapping."
      );
      return "";
    }
    return url;
  })();

  const MILKOMEDA_QUORUM = ((): number => {
    const minNumberOfVotes = selectedNetwork.common.milkomedaQuorum;
    if (minNumberOfVotes === undefined) {
      return 4;
    }
    return Number(minNumberOfVotes);
  })();

  const ALGORAND_SERVICE_FEE = ((): string => {
    const serviceFee = selectedNetwork.algorand.defaultServiceFee;
    if (serviceFee === undefined) {
      return "500000";
    }
    return serviceFee.toString();
  })();

  const EVM_GAS_LIMIT = ((): string => {
    const gasLimit = selectedNetwork.evm.gasLimit;
    if (gasLimit === undefined) {
      return "221000";
    }
    return gasLimit.toString();
  })();

  const MIN_WRAPPING_AMOUNT = ((): string => {
    const minWrappingAmount = selectedNetwork.common.defaultMinWrappingAmount;
    if (minWrappingAmount === undefined) {
      return "0";
    }
    return minWrappingAmount.toString();
  })();

  const ALGOD_URL = ((): string => {
    const algodUrl = selectedNetwork.algorand.algodURL;
    if (algodUrl === undefined) {
      return "";
    }
    return algodUrl.toString();
  })();

  const ALGOD_API_KEY = ((): string => {
    let apiKey = "";
    if (selectedNetwork.name === "mainnet") {
      // hacky way to assign api key, because we need to support already set env variable on remote env
      // however after mainnet settings are ready and envs update, we can leave
      apiKey =
        process.env.REACT_APP_ALGORAND_MAINNET_API_KEY !== undefined
          ? process.env.REACT_APP_ALGORAND_MAINNET_API_KEY ?? ""
          : process.env.REACT_APP_ALGORAND_API_KEY ?? "";
    } else {
      apiKey = process.env.REACT_APP_ALGORAND_API_KEY ?? "";
    }
    return apiKey;
  })();

  return (
    <AppContext.Provider
      value={{
        selectedNetwork,
        setSelectedNetwork,
        EVM_CHAIN_ID,
        EVM_SERVER_URL,
        EVM_CHAIN_NAME,
        EXPLORER_ALGO,
        EXPLORER_A1_EVM,
        ALGORAND_BALANCE_CHECK_MS,
        STARGATE_ADDRESS_A1,
        CONTRACT_ADDRESS_A1_EVM,
        MILKOMEDA_INDEXER_URL,
        MILKOMEDA_QUORUM,
        ALGORAND_SERVICE_FEE,
        EVM_GAS_LIMIT,
        MIN_WRAPPING_AMOUNT,
        ALGOD_URL,
        ALGOD_API_KEY,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useNetwork = (): NetworkContext => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useNetwork must be used within a NetworkProvider");
  }

  return context;
};
