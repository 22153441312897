import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import { useAppProvider } from "../context/AppContext";
import { useNetwork } from "../context/NetworkContext";
import NetworkSelect from "./NetworkSelect";
import { ReactComponent as MilkomedaA1Logo } from "../assets/icons/logos/milkomeda-A1.svg";
import { ReactComponent as MenuIcon } from "../assets/icons/burger-icon.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import { useWeb3Providers } from "../context/Web3ProviderContext";
import TooltipWithLabel from "./TooltipWithLabel";

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const { selectedNetwork } = useNetwork();
  const {
    isWrapping,
    setSelectedMainchainWallet,
    setOriginAccount,
    setDestinationAccount,
    currentStep,
    setCurrentStep,
  } = useAppProvider();
  const { setAlgodClient } = useWeb3Providers();

  return (
    <Background>
      <Header>
        <Left>
          <LogoLink to={`/${selectedNetwork.name}`} onClick={() => setCurrentStep(1)}>
            <MilkomedaLogo />
          </LogoLink>
          <NavLinkDesktop
            to={`/${selectedNetwork.name}`}
            onClick={() => setCurrentStep(1)}
          >
            Home
          </NavLinkDesktop>
          <ExternalLinkDesktop
            href={selectedNetwork.explorers.milkomeda}
            target="_blank"
            rel="noreferrer"
          >
            Bridge Explorer
          </ExternalLinkDesktop>
          <MobileMenu
            onHomeClick={() => setCurrentStep(1)}
            selectedNetwork={selectedNetwork}
          />
        </Left>
        <Right>
          {currentStep === 3 ? (
            <NetworkTooltip>
              Please be aware that if you change the network at this stage, you won't be
              able to see blockchain confirmations in the UI - before doing this, save the
              URL.
            </NetworkTooltip>
          ) : null}
          <NetworkSelect
            onNetworkConfirmed={() => {
              // reset
              setSelectedMainchainWallet(null);
              setAlgodClient(null);
              if (isWrapping) {
                setOriginAccount(undefined);
              } else {
                setDestinationAccount(undefined);
              }
              setCurrentStep(1);
            }}
          />
        </Right>
      </Header>
      <CautionInformation>
        <span>
          <strong>
            Milkomeda A1's validators will be shutting down at the end of May 2024.
          </strong>
          We urge anyone with funds on A1 to transfer them{" "}
          <strong>within the next six months</strong> to ensure their safety.{" "}
          <a
            href="https://twitter.com/milkomeda_com/status/1730385308355588553?s=61&t=xg8fF6YJdZy52Uq_UwTGjA"
            target="_blank"
            rel="noreferrer"
          >
            Read more here
          </a>
        </span>
      </CautionInformation>
      <Container>{children}</Container>
      <Footer>
        <TermOfUseLink
          href="https://milkomeda.com/terms-of-use"
          target="_blank"
          rel="noreferrer noopener"
        >
          Terms of Use
        </TermOfUseLink>
      </Footer>
    </Background>
  );
};

const MobileMenu = ({ selectedNetwork, onHomeClick }) => {
  const [isOpenMobileMenu, setOpenMobileMenu] = React.useState<boolean>(false);

  return (
    <MobileWrapper>
      <button
        onClick={() => {
          setOpenMobileMenu((prev) => !prev);
        }}
      >
        {isOpenMobileMenu ? <CloseIcon /> : <MenuIcon />}
      </button>

      {isOpenMobileMenu ? (
        <MobileDrawer>
          <NavLinkMobile
            to={`/${selectedNetwork.name}`}
            onClick={() => {
              onHomeClick();
              setOpenMobileMenu(false);
            }}
          >
            Home
          </NavLinkMobile>
          <ExternalLinkMobile
            href={selectedNetwork.explorers.milkomeda}
            target="_blank"
            rel="noreferrer"
          >
            Bridge Explorer
          </ExternalLinkMobile>
        </MobileDrawer>
      ) : null}
    </MobileWrapper>
  );
};

export default MainLayout;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background: ${(props) => props.theme.colors.gray};
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    padding-top: 24px;
    width: 100%;
  }
`;
const Header = styled.div`
  height: 80px;
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colors.grey[400]};

  @media (max-width: 768px) {
    padding: 0;
    padding-top: 24px;
    padding-bottom: 10px;
    height: auto;
    justify-content: start;
    border-bottom: 0;
  }

  .only-desktop {
    display: block;
    @media (max-width: 768px) {
      .only-desktop {
        display: none;
      }
    }
  }
  .only-mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0 24px;
    flex: 1 1 100%;
    margin-bottom: 28px;
    justify-content: space-between;
  }
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    padding: 0 24px;
    padding-bottom: 24px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    flex-direction: row-reverse;
    gap: 10px;
    border-bottom-color: ${(props) => props.theme.colors.grey[400]};
    .networkSelect {
      flex: 1;
      button {
        width: 100%;
      }
    }
  }
`;

const NavLinkDesktop = styled(Link)`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey[100]};
  &:hover {
    transition: color 0.3s;
    color: white;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const NavLinkMobile = styled(Link)`
  height: 44px;
  display: none;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.grey[100]};
  &:hover {
    transition: color 0.3s;
    color: white;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

const ExternalLinkDesktop = styled.a`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.grey[100]};
  &:hover {
    transition: color 0.3s;
    color: white;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const ExternalLinkMobile = styled.a`
  display: none;
  padding: 10px 16px;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey[100]};
  &:hover {
    transition: color 0.3s;
    color: white;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;
const LogoLink = styled(Link)`
  margin-right: 16px;
`;

const MilkomedaLogo = styled(MilkomedaA1Logo)`
  width: 210px;
  @media (max-width: 768px) {
    height: 40px;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileDrawer = styled.div`
  position: absolute;
  height: 116px;
  left: 0;
  right: 0;
  top: 76px;
  z-index: 1000;
  border-top: 1px solid #3c3c3c;
  background: #1a1a1c;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NetworkTooltip = styled(TooltipWithLabel)`
  color: #f2f3f8;
  margin-right: 10px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
`;
const TermOfUseLink = styled.a`
  padding: 10px 16px;
  font-weight: 600;
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.grey[100]};
  &:hover {
    transition: color 0.3s;
    color: white;
  }
`;

const CautionInformation = styled.div`
  margin: 20px auto 0 auto;
  padding: 20px;
  max-width: 700px;
  color: #fff;
  border-radius: 10px;
  background-color: RGB(199, 103, 8);
  a {
    text-decoration: underline;
  }
`;
