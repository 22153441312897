import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Loader from "./components/Loader";
import { ThemeProvider } from "@emotion/react";
import { AppProvider } from "./context/AppContext";
import config from "./_config/config.json";
import { A1Networks } from "./types/types";
import { NetworkProvider } from "./context/NetworkContext";
import GlobalStyles from "./styles/GlobalStyles";
import theme from "./styles/theme";
import MainLayout from "./components/MainLayout";
import { Web3Providers } from "./context/Web3ProviderContext";
const Homepage = lazy(() => import("./pages/Homepage"));
const NotFound = lazy(() => import("./pages/NotFound"));

export const networkList = Object.values(config.a1).filter((network) => {

  // devnet is turned off already, so remove both localnet and devnet from the list
  if (network.name === A1Networks.Localnet || network.name === A1Networks.Devnet) {
    return false;
  }
  // // hide localnet option on production build
  // const build = process.env.REACT_APP_MODE ?? "dev";
  // if (network.name === A1Networks.Localnet && build === "production") {
  //   return false;
  // }
  return true;
});
export const initialNetwork = config.a1[A1Networks.Mainnet]?.name;

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

const Providers = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <NetworkProvider>
        <AppProvider>
          <Web3Providers>{children}</Web3Providers>
        </AppProvider>
      </NetworkProvider>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <Providers>
        <MainLayout>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Navigate to={`/${initialNetwork}`} replace />} />
              {networkList.map((network) => (
                <React.Fragment key={network.name}>
                  <Route path={`/${network.name}`} element={<Homepage />} />
                  <Route
                    path={`/${network.name}/:txHash/:amountState/:token`}
                    element={<Homepage />}
                  />
                </React.Fragment>
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </MainLayout>
      </Providers>
    </BrowserRouter>
  );
};

export default App;
