import styled from "@emotion/styled";
import { ReactComponent as MilkomedaLogo } from "../assets/icons/logos/milkomeda.svg";
export default function Loader() {
  return (
    <Wrapper>
      <div>
        <SMilkomedaLogo />
        <h1>Loading ...</h1>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #1a1a1c;
  color: #ffffff;
  text-align: center;
  h1 {
    font-size: 1.25rem;
  }
`;

const SMilkomedaLogo = styled(MilkomedaLogo)`
  width: 65px;
  margin: auto;
  margin-bottom: 30px;
`;
