import { algoAsset, directionsList, milkomedaAsset } from "../services/constants";
import {
  AccountDetails,
  CustomAsset,
  DappSettings,
  DirectionNetworks,
  FEE_STRATEGIES,
  Networks,
  SourceDirections,
} from "../types/wallet";
import { StorageKeys } from "../utils/storageKeys";
import useLocalStorageWithExpiry from "./useLocalStorageWithExpiry";

export type HandleUpdateSettings = (
  sourceDirection?: DirectionNetworks,
  asset?: CustomAsset,
  originAccount?: AccountDetails,
  destinationAccount?: AccountDetails
) => void;
// placeholder for current settings
const useDappSettings = (): {
  dappSettings: DappSettings | undefined;
  handleUpdateDappSettings: HandleUpdateSettings;
} => {
  const [dappSettings, setDappSettings] = useLocalStorageWithExpiry(
    StorageKeys.DAPP_SETTINGS,
    undefined
  );

  const handleUpdateDappSettings = (
    sourceDirection?: DirectionNetworks,
    asset?: CustomAsset,
    mainchainAccount?: AccountDetails,
    evmAccount?: AccountDetails
  ) => {
    switch (sourceDirection?.sourceDirection) {
      case SourceDirections.MilkomedaA1: {
        const dappSettings: DappSettings = {
          direction: directionsList[1],
          selectedToken:
            asset !== undefined
              ? { ...asset }
              : {
                  id: milkomedaAsset.assetId as string,
                  assetId: milkomedaAsset.assetId as string,
                  symbol: milkomedaAsset.symbol,
                  icon: milkomedaAsset.icon,
                  balance: "0.00",
                },
          fees: FEE_STRATEGIES.A1_UNWRAPPING,
          originAccount: {
            details: evmAccount,
            network: Networks.Milkomeda,
            icon: "/media/logos/iconMilkomeda.svg",
          },
          destinationAccount: {
            details: mainchainAccount,
            network: Networks.Algorand,
            icon: "/media/logos/iconMyAlgo.svg",
          },
        };
        setDappSettings(dappSettings);
        break;
      }
      case SourceDirections.Algorand: {
        const dappSettings: DappSettings = {
          direction: directionsList[0],
          selectedToken:
            asset !== undefined
              ? { ...asset }
              : {
                  id: algoAsset.assetId as string,
                  assetId: algoAsset.assetId as string,
                  symbol: algoAsset.name,
                  icon: algoAsset.icon,
                  balance: "0.00",
                },

          originAccount: {
            details: mainchainAccount,
            network: Networks.Algorand,
            icon: "/media/logos/iconMyAlgo.svg",
          },
          destinationAccount: {
            details: evmAccount,
            network: Networks.Milkomeda,
            icon: "/media/logos/iconMilkomeda.svg",
          },
          fees: FEE_STRATEGIES.A1_WRAPPING,
        };
        setDappSettings(dappSettings);
        break;
      }
      default: {
        break;
      }
    }
  };

  return {
    dappSettings,
    handleUpdateDappSettings,
  };
};

export default useDappSettings;
