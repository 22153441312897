import { Web3Provider } from "@ethersproject/providers";
import { ethers, Transaction } from "ethers";
import {
  formatEther,
  formatUnits,
  hexlify,
  isHexString,
  parseEther,
  parseUnits,
  toUtf8Bytes,
} from "ethers/lib/utils";
import { MAIN_ASSET_ID, milkomedaAsset } from "../constants";
import { AccountDetails, CustomAsset } from "../../types/wallet";

import SideChainBridge from "../../abi/SidechainBridge.json";
import ERC20 from "../../abi/ERC20.json";
import { fromWei } from "web3-utils";
import BigNumber from "bignumber.js";

// The minimum ABI to get ERC20 Token balance
const erc20AbiJson = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  // decimals
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
];

class Evm {
  public static async getEvmAccountDetails(
    provider: Web3Provider,
    accountAddress: string,
    tokenRegistry?: CustomAsset[],
    currentAsset?: CustomAsset
  ): Promise<AccountDetails> {
    let userAssets: CustomAsset[] = [
      {
        id: MAIN_ASSET_ID as string,
        assetId: MAIN_ASSET_ID as string,
        icon: milkomedaAsset.icon,
        symbol: milkomedaAsset.symbol,
        balance: "0.00",
      },
    ];

    try {
      let evmAssets = await Evm.getEvmTokensWithBalances(
        provider,
        accountAddress,
        tokenRegistry
      );
      if (evmAssets) {
        userAssets[0].balance =
          evmAssets[0].balance === undefined || evmAssets[0].balance === null
            ? "..."
            : evmAssets[0].balance;
        userAssets = [...evmAssets];
      }
    } catch (e) {
      const err = e as Error;
      console.log(err.message);
    }

    let currentAssetToBe = {
      id: userAssets[0].id,
      assetId: userAssets[0].assetId,
      symbol: userAssets[0].symbol,
      icon: userAssets[0].icon,
      balance: userAssets[0].balance,
    };
    if (currentAsset) {
      const customAsset = userAssets.find((a) => a.id.toString() === currentAsset.id);
      if (customAsset) {
        currentAssetToBe = {
          ...customAsset,
          icon: customAsset.icon ?? "",
          balance: customAsset.balance ?? "0.00",
        };
      } else {
        // if the asset was not found on the user's wallet, then choose selected asset as current and just assign its balance to 0.00
        currentAssetToBe = {
          ...currentAsset,
          balance: "0.00",
        };
      }
    }

    const originAccount: AccountDetails = {
      address: accountAddress,
      assets: userAssets,
      currentAsset: { ...currentAssetToBe },
    };

    return originAccount;
  }

  public static getEvmTokensWithBalances = async (
    ethProvider: Web3Provider,
    evmAddress: string,
    tokenRegistry?: CustomAsset[]
  ): Promise<CustomAsset[]> => {
    let assets: CustomAsset[] = [];

    assets.push({
      id: milkomedaAsset.assetId as string,
      assetId: milkomedaAsset.assetId as string,
      symbol: "mALGO",
      decimals: 18,
      balance: formatEther(await ethProvider.getSigner().getBalance()),
      icon: milkomedaAsset.icon,
    });
    if (tokenRegistry === undefined) {
      return assets;
    }

    for (let token of tokenRegistry) {
      if (token.tokenContract !== undefined) {
        try {
          const contract = new ethers.Contract(
            token.tokenContract,
            erc20AbiJson,
            ethProvider.getSigner()
          );
          const tokenBalance = await contract.functions.balanceOf(evmAddress);
          const tokenDecimals = (await contract.functions.decimals())[0];

          assets.push({
            id: token.id,
            symbol: token.symbol,
            decimals: token.decimals,
            balance: formatUnits(tokenBalance.toString(), tokenDecimals),
            // balance: formatUnits(tokenBalance.toString(), mappedAssetMissingParts.decimals),
            icon: "",
            assetId: token.assetId,
            tokenContract: token.tokenContract,
          });
        } catch (e) {}
      }
    }
    return assets;
  };

  // unwrapping logic
  public static getERC20Allowance = async (
    contractAddressEvm: string,
    amount: string,
    signer: ethers.providers.JsonRpcSigner,
    tokenContractAddress: string
  ): Promise<{ approvedTx: any; tokenDecimals: number } | Error> => {
    try {
      const txCount = await signer.getTransactionCount();
      if (txCount == null) {
        throw new Error("txCount can't be null / void");
      }

      // Get ERC20 Allowance for the bridge
      const erc20Abi = ERC20["abi"];
      const tokenContract = new ethers.Contract(tokenContractAddress, erc20Abi, signer);
      const tokenDecimals = (await tokenContract.functions.decimals())[0];
      const approveTx = await tokenContract.functions.approve(
        contractAddressEvm,
        parseUnits(amount, tokenDecimals)
      );
      return {
        approvedTx: approveTx,
        tokenDecimals: tokenDecimals,
      };
    } catch (e) {
      console.error("Couldn't prepare & sign unwrapping request...");
      console.error(e);
      return new Error(JSON.stringify(e));
    }
  };

  public static txAssetsUnwrappingBuilder = async (
    contractAddressEvm: string,
    senderAddress: string,
    recipientAddress: string,
    amount: string,
    provider: ethers.providers.Web3Provider,
    assetId: string,
    tokenDecimals: number,
    gasLimit: string
  ): Promise<Transaction | Error> => {
    try {
      const signer = provider.getSigner();
      const txCount = await signer.getTransactionCount();

      if (txCount == null || provider == null) {
        throw new Error("txCount can't be null / void");
      }

      const GAS_LIMIT = parseInt(gasLimit ?? "221000");
      let gasPrice = await provider.getGasPrice();
      const contractAbi = SideChainBridge["abi"];

      const unwrappingRequest = {
        from: senderAddress,
        assetId: isHexString(assetId) ? assetId : `0x${assetId}`,
        to: hexlify(toUtf8Bytes(recipientAddress)),
        amount: parseUnits(amount, tokenDecimals),
      };

      const bridgeContract = new ethers.Contract(contractAddressEvm, contractAbi, signer);
      const feeFromSc = await bridgeContract.functions.UNWRAPPING_FEE();
      const unwrappingFee = fromWei(new BigNumber(feeFromSc).toString());

      let unwrappingGas = gasPrice.lt(20000000000) ? 20000000000 : gasPrice;
      const tx = await bridgeContract.functions.submitUnwrappingRequest(
        unwrappingRequest,
        {
          value: hexlify(parseEther(unwrappingFee)),
          gasLimit: hexlify(GAS_LIMIT),
          gasPrice: hexlify(unwrappingGas),
          nonce: hexlify(txCount),
        }
      );
      return tx;
    } catch (e) {
      console.error("Couldn't prepare & sign unwrapping request...");
      console.error(e);
      return Error(JSON.stringify(e));
    }
  };

  public static txUnwrappingBuilder = async (
    contractAddressEvm: string,
    senderAddress: string,
    recipientAddress: string,
    amount: string,
    provider: Web3Provider,
    assetId: string,
    gasLimit: string
  ): Promise<Transaction | Error> => {
    try {
      const signer = provider.getSigner();
      const contractAbi = SideChainBridge["abi"];
      const txCount = await signer.getTransactionCount();

      if (txCount == null || signer == null) {
        throw new Error("txCount can't be null / void");
      }

      // Unwrapping Request
      const unwrappingRequest = {
        from: senderAddress,
        assetId: isHexString(assetId) ? assetId : `0x${assetId}`, // todo
        to: hexlify(toUtf8Bytes(recipientAddress)),
        amount: parseEther(new BigNumber(amount).toFixed()),
      };

      const bridgeContract = new ethers.Contract(contractAddressEvm, contractAbi, signer);
      const feeFromSc = await bridgeContract.functions.UNWRAPPING_FEE();
      const unwrappingFee = fromWei(new BigNumber(feeFromSc).toString());

      // gas price can be returned 0 if there's clean network, hence we need to enforce to have the minimum 20gwei fee to submit evm tx to network
      let gasPrice = await signer.getGasPrice();
      const tx = await bridgeContract.functions.submitUnwrappingRequest(
        unwrappingRequest,
        {
          value: hexlify(parseEther(new BigNumber(amount).plus(unwrappingFee).toFixed())),
          gasLimit: hexlify(Number(gasLimit)),
          gasPrice: hexlify(gasPrice.lt(20000000000) ? 20000000000 : gasPrice),
          nonce: hexlify(txCount),
        }
      );

      return tx;
    } catch (e) {
      console.error("Couldn't prepare & sign unwrapping request...");
      console.error(e);
      return Error(JSON.stringify(e));
    }
  };

  public static async handleAddAssetToMetamask(
    asset: CustomAsset,
    ethProvider: Web3Provider
  ): Promise<void> {
    try {
      if (window && window.ethereum !== undefined) {
        const contract = new ethers.Contract(
          asset.tokenContract as string,
          erc20AbiJson,
          ethProvider.getSigner()
        );
        const tokenDecimals = (await contract.functions.decimals())[0] ?? 18;

        // check if asset is already watched in metamask
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: asset.tokenContract,
              symbol: asset.symbol,
              decimals: tokenDecimals,
              image: asset.icon,
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  public static switchEthChain = async ({ chainId, evmServerUrl, evmChainName }) => {
    try {
      // check if the chain to connect to is installed
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });
    } catch (error: any) {
      // 4902 tells that the chain has not yet been added to the Metamask Wallet
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainId,
                rpcUrls: [evmServerUrl],
                chainName: evmChainName,
                nativeCurrency: {
                  name: "mALGO",
                  symbol: "mALGO",
                  decimals: 18,
                },
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      }
      console.error(error);
    }
  };
}

export default Evm;
