declare module "@emotion/react" {
  export interface Theme {
    colors: {
      gray: string;
      grey: { 400: string };
      primary: string;
      black: string;
      white: string;
    };
    gradients: {
      primary: string;
      secondary: string;
      tertiary: string;
      error: string;
    };
    shadows: {
      sm: string;
    };
  }
}

const theme = {
  colors: {
    gray: "#1a1a1c",
    primary: "#ff9828",
    // secondary: "#a2bbff",
    black: "#030909",
    white: "#ffffff",

    grey: {
      // 50: "#e4e6ef",
      100: "#b5b0b0",
      400: "#3c3c3c",
    },
    // $grey-1: #f4f4f6,
    // $grey-2: #e9e9ee,
    // $grey-3: #b5b0b0,
    // $grey-4: #c7c8d4,
    // $grey-5: #8f92a9,
    // $grey-6: #707070,
  },
  gradients: {
    primary: "linear-gradient(241.11deg, #ffa23d 0%, #a2bbff 100%)",
    secondary:
      "linear-gradient(241.11deg,  rgba(162, 187, 255, 0.1) 0%,rgba(255, 162, 61, 0.2) 100%)",
    tertiary: "linear-gradient(90deg, #ffa23d 0%, #a2bbff 100%)",
    error: "linear-gradient(90deg, #8b0632 0%, #851b36 100%)",
  },
  shadows: {
    sm: "0px 4px 4px 0px #00000040",
  },
  //   $title: 22px;
  // $numbers: 24px;
  // $h1: 24px;
  // $h2: 20px;
  // $h3: 18px;
  // $h4: 16px;
  // $button: 14px;
  // $body: 14px;
  // $label: 12px;
  // $explain: 10px;
};

export default theme;
