import algosdk, { Algodv2 } from "algosdk";
import BigNumber from "bignumber.js";
import {
  AccountDetails,
  AlgorandWalletsProviders,
  CustomAsset,
} from "../../../types/wallet";
import { MAIN_ASSET_ID } from "../../constants";
import { isCorrectAlgorandAddress, isValidStr, microToAlgo } from "../../utils";
import MyAlgo from "./MyAlgo";
import PeraWallet from "./PeraWallet";

const tokenImgBaseUrl = "https://testnet.assets.algoexplorer.io/asset-logo-";

class AlgorandHandler {
  public static getAlgodClient = (
    algodUrl: string,
    algodApiKey: string
  ): algosdk.Algodv2 => {
    let algodClient: Algodv2;
    // if api key provided use purestake or any other service
    // if not then use default algorand testnet, but account info cannot be fetched
    if (isValidStr(algodApiKey) && isValidStr(algodUrl)) {
      const algoServer = algodUrl as string;
      const API_KEY = { "X-Algo-API-Token": algodApiKey as string };
      algodClient = new algosdk.Algodv2(API_KEY, algoServer, "");
    } else {
      // no api key or incorrect api key provided. No balance will be fetched
      algodClient = new algosdk.Algodv2(
        "",
        "https://node.testnet.algoexplorerapi.io",
        ""
      );
    }
    return algodClient;
  };

  public static convertToNormalized(amount: string, decimals: number): string {
    let convertedAmount = "0.00";
    // console.log(amount, decimals);
    try {
      // then microalgos to algos conversion needed
      if (decimals === 0) {
        convertedAmount = microToAlgo(amount).toString();
      }

      if (decimals > 0) {
        convertedAmount = new BigNumber(amount)
          .div(new BigNumber(10).pow(new BigNumber(decimals)))
          .toString();
      }
    } catch (e) {}
    return convertedAmount;
  }

  public static convertToDefaultValue(amount: string, decimals: number): string {
    let convertedAmount = "0.00";
    try {
      // then microalgos to algos conversion needed
      if (decimals === 0) {
        convertedAmount = microToAlgo(amount).toString();
      }

      if (decimals > 0) {
        convertedAmount = new BigNumber(amount)
          .multipliedBy(new BigNumber(10).pow(new BigNumber(decimals)))
          .toString();
      }
    } catch (e) {}
    return convertedAmount;
  }

  public static async getAlgorandAssetsListForAccount(
    algodClient: Algodv2,
    accountAssets: CustomAsset[]
  ): Promise<CustomAsset[]> {
    let gatheredAssets: CustomAsset[] = [];
    for (let asset of accountAssets) {
      try {
        const assetDetails = await algodClient.getAssetByID(asset["asset-id"]).do();
        const amount = this.convertToNormalized(
          asset["amount"] as string,
          assetDetails.params["decimals"]
        );
        gatheredAssets.push({
          id: assetDetails["index"] !== "" ? assetDetails["index"] : MAIN_ASSET_ID,
          assetId: assetDetails["index"] !== "" ? assetDetails["index"] : MAIN_ASSET_ID,
          symbol: assetDetails.params["unit-name"],
          decimals: assetDetails.params["decimals"],
          icon: `${tokenImgBaseUrl}${assetDetails["index"]}.image`,
          balance: amount,
        });
      } catch (e) {
        // since we're getting details of assets one by one, if there's some problem with one of them, just log the error and move on
        console.error(e);
      }
    }
    return gatheredAssets;
  }

  public static async getAlgorandAccountDetails(
    algodClient: Algodv2,
    accountAddress: string,
    currentAsset?: CustomAsset
  ): Promise<AccountDetails> {
    let userAssets: CustomAsset[] = [
      {
        id: MAIN_ASSET_ID as string,
        assetId: MAIN_ASSET_ID as string,
        icon: "/media/logos/iconMyAlgo.svg",
        symbol: "ALGO",
        balance: "0.00",
      },
    ];
    let algoBalance: string | null;
    let minBalance: string = "1000000";
    try {
      let accountInfo = await algodClient.accountInformation(accountAddress).do();
      algoBalance = accountInfo.amount.toString();
      minBalance = accountInfo["min-balance"].toString();
      userAssets[0].balance =
        algoBalance === undefined || algoBalance === null
          ? "..."
          : microToAlgo(algoBalance).toString();

      const assets = await AlgorandHandler.getAlgorandAssetsListForAccount(
        algodClient,
        accountInfo.assets
      );
      userAssets = [...userAssets, ...assets];
    } catch (e) {
      const err = e as Error;
      console.log(err.message);
      algoBalance = null;
    }

    let currentAssetToBe = {
      id: userAssets[0].id,
      assetId: userAssets[0].assetId,
      symbol: userAssets[0].symbol,
      icon: userAssets[0].icon,
      balance: userAssets[0].balance,
      decimals: userAssets[0].decimals,
    };
    if (currentAsset) {
      const customAsset = userAssets.find((a) => a.id.toString() === currentAsset.id);

      if (customAsset) {
        currentAssetToBe = {
          id: customAsset.id,
          assetId: customAsset.assetId,
          symbol: customAsset.symbol,
          icon: customAsset.icon ?? "",
          balance: customAsset.balance ?? "0.00",
          decimals: customAsset.decimals ?? 6,
        };
      } else {
        // if the asset was not found on the user's wallet, then choose selected asset as current and just assign its balance to 0.00
        currentAssetToBe = {
          ...currentAsset,
          balance: "0.00",
          decimals: 6,
        };
      }
    }

    const originAccount: AccountDetails = {
      address: accountAddress,
      assets: userAssets,
      currentAsset: { ...currentAssetToBe },
      minAccountBalance: microToAlgo(minBalance),
    };
    return originAccount;
  }

  public static doesAccountOptInSelectedAsset = async (
    algodClient: Algodv2,
    accountAddress: string,
    assetId: number
  ): Promise<boolean> => {
    try {
      let accountInfo = await algodClient.accountInformation(accountAddress).do();
      const assetFound = accountInfo.assets.find(
        (asset) => asset["asset-id"] === assetId
      );

      if (assetFound === undefined || assetFound === null) return false;
      return true;
    } catch (e) {
      return false;
    }
  };

  // opt in asset and the result is the transaction id
  public static optInAlgorandAsset = async (
    algodClient: Algodv2 | null,
    algorandWalletsProvider: AlgorandWalletsProviders | undefined,
    senderAddress: string,
    assetId: number
  ): Promise<string | null | Error> => {
    try {
      if (
        !isCorrectAlgorandAddress(senderAddress) ||
        algodClient === null ||
        algorandWalletsProvider === undefined
      ) {
        return null;
      }
      let assetID = Number(assetId); // change to your own assetID
      let params = await algodClient.getTransactionParams().do();
      let revocationTarget = undefined;
      let closeRemainderTo = undefined;
      let note = undefined;
      let amount = 0;
      // optin in is basically sending tx of 0 amount of given asset from one account and receiving it on the same one
      let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(
        senderAddress,
        senderAddress,
        closeRemainderTo,
        revocationTarget,
        amount,
        note,
        assetID,
        params
      );

      let tx: string | null | Error = null;
      if (algorandWalletsProvider === AlgorandWalletsProviders.MyAlgo) {
        tx = await MyAlgo.signAndSendTransaction(algodClient, txn);
      } else {
        tx = await PeraWallet.signAndSendTransaction(algodClient, txn, [senderAddress]);
      }

      if (tx instanceof Error) {
        throw tx;
      }
      return tx;
    } catch (e) {
      const err = e as Error;

      if (err.message.includes("overspend")) {
        return new Error(
          "Couldn't perform opt-in. Please check if you have sufficient amount of ALGOs to pay the transaction fee."
        );
      }
      return null;
    }
  };
}

export default AlgorandHandler;
